import {
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Link,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { Partner } from "../../account-status/types";

import { StatusCard } from "./StatusCard";

import { Route } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";

export const Successful: React.FC<{
  partner?: Partner;
}> = ({ partner }) => {
  const partnerName = <Text className="fs-mask">{partner?.name}</Text>;

  return (
    <StatusCard
      icon="AccountVerifiedTick"
      title={<Trans>You&apos;re all set!</Trans>}
      description={
        partner?.name ? (
          <Trans>
            You are fully verified and now ready to start taking payments.
          </Trans>
        ) : (
          <Trans>
            You are fully verified and now ready to start taking payments,
            continue to the dashboard to start using the product.
          </Trans>
        )
      }
      body={
        <Box css={{ textAlign: "center" }}>
          <LinkBuilder absolute={true} route={Route.Home}>
            {({ href }) => (
              <Link
                variant={ButtonVariant.PrimaryOnLight}
                layout={ButtonLayout.Inline}
                size={ButtonSize.Md}
                href={partner?.url ?? href}
              >
                {partner?.name ? (
                  <Trans>Back to {partnerName}</Trans>
                ) : (
                  <Trans>Go to dashboard</Trans>
                )}
              </Link>
            )}
          </LinkBuilder>
        </Box>
      }
    />
  );
};
