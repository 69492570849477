import { DayOfWeek, Month } from "@gocardless/api/dashboard/types";
import { i18n } from "@lingui/core";
import { selectOrdinal, t } from "@lingui/macro";

export const WEEKDAYS: [string, DayOfWeek][] = [
  ["Monday", DayOfWeek.Monday],
  ["Tuesday", DayOfWeek.Tuesday],
  ["Wednesday", DayOfWeek.Wednesday],
  ["Thursday", DayOfWeek.Thursday],
  ["Friday", DayOfWeek.Friday],
  ["Saturday", DayOfWeek.Saturday],
  ["Sunday", DayOfWeek.Sunday],
];

export const MONTHS: [string, Month][] = [
  ["January", Month.January],
  ["February", Month.February],
  ["March", Month.March],
  ["April", Month.April],
  ["May", Month.May],
  ["June", Month.June],
  ["July", Month.July],
  ["August", Month.August],
  ["September", Month.September],
  ["October", Month.October],
  ["November", Month.November],
  ["December", Month.December],
];

export const LAST_DAY_OF_MONTH_VALUE = -1;
export const MAX_DAY_OF_MONTH_SHARED_BY_ALL_MONTHS = 28;
export const dayOfMonthOptionValues = Array.from(
  Array(MAX_DAY_OF_MONTH_SHARED_BY_ALL_MONTHS).keys(),
  (index) => index + 1
);

export const getValidDayOfMonthOption = (value: number) =>
  dayOfMonthOptionValues.includes(value) ? value : LAST_DAY_OF_MONTH_VALUE;

export const getMonthByIndex = (index: number) => MONTHS[index]?.[1];
export const getIndexOfMonth = (month: Month) =>
  MONTHS.findIndex(([, monthValue]) => month === monthValue);

export const getWeekdayByIndex = (index: number) => WEEKDAYS[index]?.[1];
export const getIndexOfWeekday = (dayOfWeek: DayOfWeek) =>
  WEEKDAYS.findIndex(([, weekday]) => dayOfWeek === weekday);
export const fieldName = "day_of_week";

export const getDayOfMonth = (day: number): string =>
  day === 29 || day === -1
    ? i18n._(t({ id: "last-day", message: "last day" }))
    : getDayOfMonthOrdinal(day);

export const getDayOfMonthOrdinal = (day: number): string =>
  i18n._(
    t({
      id: "day-of-month-ordinal",
      message: selectOrdinal(day, {
        one: "#st",
        two: "#nd",
        few: "#rd",
        many: "#th",
        other: "#th",
      }),
    })
  );

export const getLastDayOfMonth = (month: number, year: number): number => {
  const isLeapYear =
    Number.isInteger(year / 400) ||
    (Number.isInteger(year / 4) && !Number.isInteger(year / 100));
  const lastDayOfMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return lastDayOfMonth[month] || 30;
};
