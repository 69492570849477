import { useContext } from "react";

import { BrazeContext, BrazeContextType } from "./BrazeProvider";

export const useBraze = (): BrazeContextType => {
  const context = useContext(BrazeContext);

  if (!context) {
    throw new Error("useBraze must be used within a BrazeProvider");
  }

  return context;
};
