import {
  CreditorSelfResponseBody,
  CreditorsVerificationStatus,
} from "@gocardless/api/dashboard/types";
import { Box } from "@gocardless/flux-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Partner } from "../account-status/types";
import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";
import { MerchantOnboardingSetupEvents } from "../common/constants/MerchantOnboardingSetupEvents";
import { useCollectionsPermitted } from "../../SetupPayments/common/hooks/useCollectionsPermitted";

import { DocumentsToUpload } from "./required-actions/DocumentsToUpload";
import { useRequiredDocuments } from "./required-documents/useRequiredDocuments";
import { ActionRequired } from "./status-cards/ActionRequired";
import { Failed } from "./status-cards/Failed";
import { InReview } from "./status-cards/InReview";
import { Successful } from "./status-cards/Successful";
import { getPending } from "./helpers/requiredDocumentsFilter";
import { usePennyTestSurvey } from "./hooks/usePennyTestSurvey";

import { HelpGettingStarted } from "src/components/routes/Setup/verification-status/help-getting-started/HelpGettingStarted";
import { useLROSurvey } from "src/components/routes/SetupPayments/common/hooks/useLROSurvey";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { NextSteps } from "src/components/routes/Setup/verification-status/next-steps/NextSteps";
import { CountryCodes } from "src/common/country";

const INTERVALS = [1, 2, 4, 8, 16, 32, 60];

export const OptimisedVerificationStatus: React.FC<{
  status: CreditorsVerificationStatus;
  partner?: Partner;
  mutateAccountStatus: () => Promise<
    void | CreditorSelfResponseBody | undefined
  >;
}> = ({ status, partner, mutateAccountStatus }) => {
  const {
    data: requiredDocuments,
    hasUnsuccessful,
    mutate,
    loading,
  } = useRequiredDocuments();

  const creditor = usePrimaryCreditor();
  const organisation = useOrganisation();
  const { sendEvent } = useSegmentForSetup();
  const { collectionsEnabledTrackingAttribute } = useCollectionsPermitted();
  const { triggerSurvey } = useLROSurvey();
  const { triggerPennyTestSurvey } = usePennyTestSurvey(creditor);

  const [creditorVerificationStatus, setCreditorVerificationStatus] =
    useState<CreditorsVerificationStatus>(status);
  const [refresh, setRefresh] = useState(false);

  /**
   * State to control whether data fetching related to mutations is paused
   * in show updated account status useEffect section.
   *
   * The call to `await mutateAccountStatus()` can lead to multiple re-renders,
   * potentially causing unexpected behavior in the VerifyWithOnfidoPopup.tsx.
   * We use this to control the data fetching flow based on
   * the modal's state during the identity verification process.
   */
  const [mutationFetchingPaused, setMutationFetchingPaused] =
    useState<boolean>(false);

  const { isVariationOn: isPennyTestImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PENNY_TEST_IMPROVEMENTS,
    });

  const updateRequiredDocuments = useCallback(async () => {
    await mutate();

    // Get refreshed list of required documents after 60 seconds (occurs once)
    setTimeout(() => {
      mutate();
    }, 60000);
  }, [mutate]);

  useEffect(() => {
    if (loading) return;

    const pendingDocuments = getPending(requiredDocuments);
    const requiredDocumentNames = pendingDocuments.map(
      (requiredDocument) => requiredDocument.name
    );
    const uniqueRequiredDocumentNames = [...new Set(requiredDocumentNames)];
    const requiredDocumentsCount = pendingDocuments.length;

    sendEvent(MerchantOnboardingSetupEvents.SuccessScreenPageViewed, {
      verification_status: status,
      new_design: true,
      pending_document_types: uniqueRequiredDocumentNames,
      pending_documents_count: requiredDocumentsCount,
      ...collectionsEnabledTrackingAttribute,
    });
    triggerSurvey();
    triggerPennyTestSurvey();

    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, loading]);

  const [intervalIndex, setIntervalIndex] = useState<number>(0);

  // show updated account status
  useEffect(() => {
    if (
      !isPennyTestImprovementsEnabled ||
      mutationFetchingPaused ||
      creditorVerificationStatus === CreditorsVerificationStatus.Successful
    )
      return;

    const interval = setInterval(
      async () => {
        await mutateAccountStatus();

        if (creditorVerificationStatus !== status) {
          setCreditorVerificationStatus(status);
        }

        setRefresh((prev) => !prev);

        if (intervalIndex < INTERVALS.length - 1) {
          setIntervalIndex(intervalIndex + 1);
        }
      },
      (INTERVALS[intervalIndex] as number) * 1000
    );

    return () => clearInterval(interval);
  }, [
    refresh,
    mutateAccountStatus,
    status,
    creditorVerificationStatus,
    isPennyTestImprovementsEnabled,
    mutationFetchingPaused,
    intervalIndex,
  ]);

  const body = useMemo(() => {
    if (
      !creditor ||
      !creditor.id ||
      !creditor.geo ||
      !creditor.creditor_type ||
      !organisation ||
      !organisation.primary_admin_email
    )
      return null;

    const creditorId = creditor.id;
    const creditorGeo = creditor.geo as CountryCodes;
    const creditorType = creditor.creditor_type;
    const primaryAdminEmail = organisation.primary_admin_email;

    if (hasUnsuccessful)
      return <Failed primaryAdminEmail={primaryAdminEmail} />;

    switch (creditorVerificationStatus) {
      case CreditorsVerificationStatus.ActionRequired:
        return (
          <>
            <ActionRequired
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
            />
            <DocumentsToUpload
              creditorId={creditorId}
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
              updateRequiredDocuments={updateRequiredDocuments}
              notificationEmail={primaryAdminEmail}
              setMutationFetchingPaused={setMutationFetchingPaused}
            />
          </>
        );
      case CreditorsVerificationStatus.InReview:
        return (
          <>
            <InReview
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
            />
            <DocumentsToUpload
              creditorId={creditorId}
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
              updateRequiredDocuments={updateRequiredDocuments}
              notificationEmail={primaryAdminEmail}
            />
            <HelpGettingStarted
              verificationStatus={creditorVerificationStatus}
            />
            <NextSteps
              notificationEmail={primaryAdminEmail}
              partner={partner}
            />
          </>
        );
      case CreditorsVerificationStatus.Successful:
        return (
          <>
            <Successful partner={partner} />
            <HelpGettingStarted
              verificationStatus={creditorVerificationStatus}
            />
          </>
        );
    }
  }, [
    creditor,
    organisation,
    hasUnsuccessful,
    creditorVerificationStatus,
    requiredDocuments,
    updateRequiredDocuments,
    partner,
  ]);

  return (
    <Box maxWidth={700} className="fs-unmask" id="verification-status">
      {body}
    </Box>
  );
};
