import { useEffect, useState } from "react";

import { BookSupportCallModal } from "src/components/routes/Setup/common/components/BookSupportCall/bookSupportCallModal";
import { useBookSupportCall } from "src/components/routes/Setup/common/components/BookSupportCall/useBookSupportCall";
import { BookMeetingModal } from "src/components/routes/Setup/common/components/BookSupportCall/bookMeetingModal";

export const BookSupportCall: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [bookMeetingOpen, setBookMeetingOpen] = useState<boolean>(false);
  const { bookSupportCall, showDialog, onDismissDialog } = useBookSupportCall();

  useEffect(() => {
    setDialogOpen(showDialog);
  }, [showDialog]);
  return (
    <>
      <BookSupportCallModal
        open={dialogOpen}
        closeDialog={() => {
          setDialogOpen(false);
          onDismissDialog();
        }}
        bookSupportCall={() => {
          bookSupportCall();
          setBookMeetingOpen(true);
        }}
      />
      <BookMeetingModal
        open={bookMeetingOpen}
        closeDialog={() => setBookMeetingOpen(false)}
      />
    </>
  );
};
