import {
  Box,
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonVariant,
  Color,
  FontWeight,
  Glyph,
  H6,
  Icon,
  P,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { SIDEBAR_STEPS_BUTTON_ID } from "../../common/components/Header";

import { Route, routerPush } from "src/common/routing";
import { Partner } from "src/components/routes/Setup/account-status/types";

interface NextStepsProps {
  notificationEmail: string;
  partner?: Partner;
}

export const NextSteps: React.FC<NextStepsProps> = ({
  notificationEmail,
  partner,
}) => {
  const partnerName = <Text className="fs-mask">{partner?.name}</Text>;
  const emailAddress = (
    <Text weight={FontWeight.Medium} className="fs-mask">
      {notificationEmail}
    </Text>
  );

  return (
    <Box
      bg={Color.White}
      borderWidth={1}
      borderColor={Color.Greystone_1400_A16}
      borderRadius={1}
      gutterV={[2, 4]}
      gutterH={[1.5, 4]}
      spaceAbove={[1.5, 3]}
    >
      <H6 preset={TypePreset.Heading_06}>
        <Trans>Next steps...</Trans>
      </H6>
      <Space v={2} />
      <P weight={FontWeight.SemiBold} preset={TypePreset.Body_01}>
        <Trans>No further actions required</Trans>
      </P>
      <Space v={1} />
      <P preset={TypePreset.Body_01}>
        <Trans>
          We will email the account {emailAddress} when we have completed our
          checks. If we require further information we will notify you via the
          email above.
        </Trans>
      </P>
      <Space v={2} />
      <ButtonGroup arrangement={["column-center", null, "row-start"]}>
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
          onClick={() => {
            if (partner?.url) {
              window.location.href = partner?.url;
            } else {
              routerPush({ route: Route.Home });
            }
          }}
        >
          {partnerName && partner?.url ? (
            <Trans>Back to {partnerName}</Trans>
          ) : (
            <Trans>Go to dashboard</Trans>
          )}
        </Button>

        <Button
          layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
          variant={ButtonVariant.TextOnLight}
          onClick={() => {
            document.getElementById(SIDEBAR_STEPS_BUTTON_ID)?.click();
          }}
        >
          <Trans>
            Edit details <Icon name={Glyph.ArrowForward} size="12px" />
          </Trans>
        </Button>
      </ButtonGroup>
    </Box>
  );
};
