import {
  DayOfWeek,
  IntervalUnit,
  Month,
  PlanResource,
  SubscriptionResource,
} from "@gocardless/api/dashboard/types";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { capitalize } from "lodash";

import { Currency } from "src/common/currencies";
import { dayOfWeekAsString } from "src/common/date-time";
import { monthToName } from "src/common/months";
import { getDayOfMonth } from "src/utils/date-parser";
import { convertCentsToCurrency } from "src/utils/currency-parser";
import { i18nMarkTranslate } from "src/components/i18n";

type Resource =
  | PlanResource
  | (SubscriptionResource & { day_of_week?: DayOfWeek });

interface IntervalMessageargs {
  intervalUnit?: IntervalUnit;
  interval?: number | string;
  dayOfWeek?: DayOfWeek | null;
  dayOfMonth?: string | number | null;
  month?: Month | null;
  options?: {
    short?: boolean;
  };
}
export const getIntervalMessage = ({
  intervalUnit,
  interval,
  dayOfWeek,
  dayOfMonth,
  month,
  options,
}: IntervalMessageargs) => {
  const WEEKS = i18n._(t({ id: "interval-weeks", message: "weeks" }));
  const MONTHS = i18n._(t({ id: "interval-months", message: "months" }));
  const YEARS = i18n._(t({ id: "interval-years", message: "years" }));

  const WEEK_LONG = i18n._(
    t({ id: "request-payment.every-week", message: `every week` })
  );
  const MONTH_LONG = i18n._(
    t({ id: "request-payment.every-month", message: `every month` })
  );
  const YEAR_LONG = i18n._(
    t({ id: "request-payment.every-year", message: "every year" })
  );

  const INTERVAL_UNIT_WEEKLY = i18n._(t({ id: "weekly", message: "weekly" }));
  const INTERVAL_UNIT_MONTHLY = i18n._(
    t({ id: "monthly", message: "monthly" })
  );
  const INTERVAL_UNIT_YEARLY = i18n._(t({ id: "yearly", message: "yearly" }));

  const intervalUnitConfig: Record<IntervalUnit, string> = options?.short
    ? {
        weekly: INTERVAL_UNIT_WEEKLY,
        monthly: INTERVAL_UNIT_MONTHLY,
        yearly: INTERVAL_UNIT_YEARLY,
      }
    : {
        weekly: WEEK_LONG,
        monthly: MONTH_LONG,
        yearly: YEAR_LONG,
      };

  const intervalConfig: Record<IntervalUnit, string> = {
    weekly: WEEKS,
    monthly: MONTHS,
    yearly: YEARS,
  };

  if (!intervalUnit) {
    return "";
  }
  if (interval && intervalUnit) {
    if (intervalUnit === IntervalUnit.Weekly && dayOfWeek) {
      return Number(interval) === 1
        ? i18n._(
            t({
              id: "interval-with-every-1-on-day-of-week",
              message: `${intervalUnitConfig[intervalUnit]} on ${capitalize(
                dayOfWeekAsString(dayOfWeek)
              )}`,
            })
          )
        : i18n._(
            t({
              id: "interval-with-every-n-on-day-of-week",
              message: `Every ${interval} ${
                intervalConfig[intervalUnit]
              } on ${capitalize(dayOfWeekAsString(dayOfWeek))}`,
            })
          );
    }
  }

  if (intervalUnit === IntervalUnit.Monthly && dayOfMonth) {
    const dayOfMonthOrdinal = getDayOfMonth(Number(dayOfMonth));
    return Number(interval) === 1
      ? i18n._(
          t({
            id: "interval-with-every-1-on-day-of-month",
            message: `${intervalUnitConfig[intervalUnit]} on the ${dayOfMonthOrdinal}`,
          })
        )
      : i18n._(
          t({
            id: "interval-with-every-n-on-day-of-month",
            message: `Every ${interval} ${intervalConfig[intervalUnit]} on the ${dayOfMonthOrdinal}`,
          })
        );
  }

  if (intervalUnit === IntervalUnit.Yearly && dayOfMonth && month) {
    const dayOfMonthOrdinal = getDayOfMonth(Number(dayOfMonth));

    return i18n._(
      t({
        id: "interval-with-every-1-on-day-of-month-of-year",
        message: `${
          intervalUnitConfig[intervalUnit]
        } on the ${dayOfMonthOrdinal} of ${capitalize(monthToName()[month])}`,
      })
    );
  }

  if (Number(interval) !== 1) {
    switch (intervalUnit) {
      case IntervalUnit.Weekly:
        return i18n._(
          t({
            id: "request-payment.every-x-weeks",
            message: `Every ${interval} ${intervalConfig[intervalUnit]}`,
          })
        );

      case IntervalUnit.Monthly:
        return i18n._(
          t({
            id: "request-payment.every-x-months",
            message: `Every ${interval} ${intervalConfig[intervalUnit]}`,
          })
        );

      case IntervalUnit.Yearly:
        return i18n._(
          t({
            id: "request-payment.every-x-years",
            message: `Every ${interval} ${intervalConfig[intervalUnit]}`,
          })
        );
    }
  }

  return `${intervalUnitConfig[intervalUnit]}`;
};

/**
 * Returns a string describing a recurring payment in plain English.
 *
 * @param {Resource} resource - The payment resource containing the payment details.
 * @return {string} A string describing the recurring payment.
 */
export const getRecurringPaymentDescription = (
  resource: Resource,
  opts: { showEmptyFractionalPart?: boolean } = {}
) => {
  const {
    amount,
    currency,
    interval_unit: intervalUnit,
    interval,
    day_of_week: dayOfWeek,
    month,
    day_of_month: dayOfMonth,
  } = resource;

  const intervalMessage = getIntervalMessage({
    intervalUnit,
    interval,
    dayOfWeek,
    dayOfMonth,
    month,
    options: {
      short: !amount,
    },
  });

  const baseResult = `${
    amount && currency
      ? convertCentsToCurrency(amount as number, currency as Currency, opts)
      : ""
  } ${intervalMessage.charAt(0).toLowerCase() + intervalMessage.slice(1)}`
    .trimStart()
    .trimEnd();

  return baseResult.charAt(0).toUpperCase() + baseResult.slice(1);
};

export const getRecurringPaymentNumberOfPayments = (plan: PlanResource) => {
  if (plan.count) {
    return i18n._(t({ message: `for a total of ${plan.count} payments` }));
  } else {
    return i18n._(t({ message: "until further notice" }));
  }
};

/**
 * Returns a string which describes the plan timings in plain English. For
 * example a monthly plan collected on the 25th of the month would return
 * "Every month via Direct Debit on the 25th".
 */
export const getPlanTimings = (
  resource: Resource,
  includePaymentMethod = true
): string => {
  const viaDirectDebit = i18n._(
    t({
      id: "plan.description-via-direct-debit",
      message: "via Direct Debit",
    })
  );
  const { amount, ...rest } = resource;
  const baseResult = getRecurringPaymentDescription(rest);
  if (includePaymentMethod) {
    return `${baseResult} ${viaDirectDebit}`;
  }
  return baseResult;
};

export const getInstalmentTimings = (
  numberOfInstalments: number,
  resource?: Resource
): string =>
  // TODO: add translation
  i18nMarkTranslate(
    `${numberOfInstalments} Instalment payments ${getRecurringPaymentDescription(
      { ...resource, amount: undefined }
    )}`
  );
