import { Dialog } from "@gocardless/flux-react";
import { useEffect } from "react";

import {
  CHILLI_PIPER_DOMAIN,
  useBookSupportCall,
} from "src/components/routes/Setup/common/components/BookSupportCall/useBookSupportCall";
import { TrackingEvent } from "src/common/trackingEvents";

interface BooKMeetingModalProps {
  open: boolean;
  closeDialog: () => void;
}
export const BookMeetingModal: React.FC<BooKMeetingModalProps> = ({
  open,
  closeDialog,
}) => {
  const { bookingUrl, sendBookCallEvent } = useBookSupportCall();
  useEffect(() => {
    if (!open) return;
    const handleEvent = (event: MessageEvent) => {
      if (event.origin !== CHILLI_PIPER_DOMAIN) return;
      if (event.data.action === "close") {
        sendBookCallEvent(
          TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_CALENDAR_DISMISSED
        );
        closeDialog();
      }
      if (event.data.action === "timeslot-selected") {
        sendBookCallEvent(
          TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_CALENDAR_TIME_SELECTED
        );
      }
      if (event.data.action === "booking-confirmed") {
        sendBookCallEvent(
          TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_CALENDAR_BOOKED
        );
      }
    };
    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [open, closeDialog, sendBookCallEvent]);
  return (
    <Dialog
      open={open}
      closeAction={{
        onClose: closeDialog,
        label: "Close",
        position: "absolute",
      }}
      bodyGutterV={0}
      bodyGutterH={0}
      width={["100%", null, "720px"]}
      height="868px"
    >
      <iframe
        id="modalIframe"
        src={bookingUrl}
        title="chillipiper-booking-modal"
        width="100%"
        height="868px"
        frameBorder="0"
      />
    </Dialog>
  );
};
