import { CreditorType } from "@gocardless/api/dashboard/types";
import { Box, Separator } from "@gocardless/flux-react";
import { useMemo } from "react";

import { getUploadConfig } from "../config";
import { RequiredDocument } from "../required-documents/useRequiredDocuments";
import {
  getInReview,
  getPending,
  getSuccessful,
} from "../helpers/requiredDocumentsFilter";

import { DetailsSubmitted } from "././status-item/DetailsSubmitted";

import { CountryCodes } from "src/common/country";

export const StatusList: React.FC<{
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  requiredDocuments: RequiredDocument[];
}> = ({ creditorGeo, creditorType, requiredDocuments }) => {
  const statusItems = useMemo(() => {
    const result: JSX.Element[] = [<DetailsSubmitted key={0} />];

    const successful = getSuccessful(requiredDocuments);
    const pending = getPending(requiredDocuments);
    const inReview = getInReview(requiredDocuments);

    [...successful, ...inReview, ...pending].forEach((requiredDocument) => {
      const { statusComponent: StatusComponent } = getUploadConfig(
        requiredDocument.name,
        creditorGeo
      );

      if (!StatusComponent) return;

      const key = requiredDocument.verificationId;

      result.push(<Separator key={`sep-${key}`} spacing={[[1, 0]]} />);

      result.push(
        <StatusComponent
          key={`item-${key}`}
          creditorType={creditorType}
          requiredDocument={requiredDocument}
        />
      );
    });

    return result;
  }, [requiredDocuments, creditorGeo, creditorType]);

  return <Box>{statusItems}</Box>;
};
