import { TrackingEvent } from "src/common/trackingEvents";
import { useBraze } from "src/technical-integrations/braze/useBraze";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const useLROSurvey = () => {
  const { isVariationOn: cpvVariantEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const { sendCustomEvent } = useBraze();

  const triggerSurvey = () => {
    if (cpvVariantEnabled) {
      sendCustomEvent(TrackingEvent.TRIGGER_SURVEY_UNIFIED_FLOW_VARIANT);
    } else {
      sendCustomEvent(TrackingEvent.TRIGGER_SURVEY_UNIFIED_FLOW_CONTROL);
    }
  };

  return {
    triggerSurvey,
  };
};
