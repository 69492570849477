import { Currency, currencyToSymbol } from "src/common/currencies";

export const convertCentsToCurrency = (
  amount: number,
  currency: Currency,
  { showEmptyFractionalPart = true }: { showEmptyFractionalPart?: boolean } = {}
) =>
  `${currencyToSymbol[currency]}${Number(amount / 100)
    .toFixed(showEmptyFractionalPart || amount % 100 !== 0 ? 2 : 0)
    .toString()
    .padStart(2, "0")}`;
