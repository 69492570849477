import {
  CreditorType,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { Box, ColorPreset } from "@gocardless/flux-react";

import { DocumentRequirement } from "../../constants/DocumentRequirement";
import { AcceptedDocuments } from "../../../common/constants/AcceptedDocuments";
import { RequiredActionStatusIcon } from "../../common/RequiredActionStatusIcon";

import { CountryCodes } from "src/common/country";

export interface RequiredActionCardProps {
  creditorId: string;
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  verificationId: string;
  actionStatus: RequiredActionsStatus;
  actionComponent: React.FC<RequiredActionCardProps>;
  entity: Record<string, string>;
  acceptedDocuments: AcceptedDocuments[];
  documentRequirements?: DocumentRequirement[];
  updateRequiredDocuments: () => Promise<void>;
  bankAccountNumberEnding?: string;
  notificationEmail: string;
  setMutationFetchingPaused?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
}

export const RequiredActionCard: React.FC<RequiredActionCardProps> = (
  props
) => {
  const {
    actionStatus,
    actionComponent: ActionComponent,
    verificationId,
  } = props;

  return (
    <Box
      gutterV={[2, 3]}
      gutterH={[1.5, 3]}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={1.5}
      borderWidth={1}
      bg={ColorPreset.BackgroundLight_01}
      layout="flex"
      flexDirection={["column", "row"]}
      id={verificationId}
    >
      <Box spaceAfter={1} spaceBelow={[1, 0]}>
        <RequiredActionStatusIcon status={actionStatus} />
      </Box>
      <ActionComponent {...props} />
    </Box>
  );
};
