import { FC, useEffect, useRef } from "react";
import { Box, useTheme } from "@gocardless/flux-react";
import { Global } from "@emotion/react";

import { boxStyle, odometerThemeStyle } from "./OdometerAnimation.styles";

import { useMediaQuery } from "src/hooks/useMediaQuery";

export interface OdometerAnimationProps {
  startValue: number;
  endValue: number;
  delay?: number;
}

export const OdometerAnimation: FC<OdometerAnimationProps> = ({
  startValue,
  endValue,
  delay = 0,
}) => {
  const { theme } = useTheme();
  const {
    mediaQueryList: { matches: prefersReducedMotion },
    loaded,
  } = useMediaQuery("(prefers-reduced-motion)");

  const odometerNode = useRef<HTMLDivElement>(null);
  const odometer = useRef<EXTERNAL>();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    (async () => {
      if (!loaded) return;

      const Odometer = (await import("odometer")).default;

      odometer.current = new Odometer({
        el: odometerNode.current,
        auto: false,
        format: "d",
        value: prefersReducedMotion ? endValue : startValue,
      });

      if (!prefersReducedMotion) {
        timeoutId = setTimeout(() => odometer.current?.update(endValue), delay);
      }
    })();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [prefersReducedMotion, loaded, startValue, endValue, delay]);

  return (
    <>
      <Global styles={odometerThemeStyle(theme)} />
      <Box spaceAbove={1} spaceBelow={1} css={[boxStyle(theme)]}>
        <div ref={odometerNode} />
      </Box>
    </>
  );
};
