import { CreditorResource } from "@gocardless/api/dashboard/types";

import { TrackingEvent } from "src/common/trackingEvents";
import { useBraze } from "src/technical-integrations/braze/useBraze";

export const usePennyTestSurvey = (creditor: CreditorResource | undefined) => {
  const { sendCustomEvent } = useBraze();

  const triggerPennyTestSurvey = () => {
    if (localStorage.getItem(`setup.verify-bank.${creditor?.id}`)) {
      sendCustomEvent(TrackingEvent.TRIGGER_SURVEY_PENNY_TEST_COMPLETION);
    }
  };

  return {
    triggerPennyTestSurvey,
  };
};
