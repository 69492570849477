import {
  RequiredActionsStatus,
  RequiredVerificationStatus,
} from "@gocardless/api/dashboard/types";
import { useEffect } from "react";

import { OptimisedVerificationStatus } from "../verification-status";
import { RewardFeeDiscountDialogSetupVariant } from "../../SetupPayments/home/CollectionsEnabled/RewardFeeDiscountDialogSetupVariant";

import { useAccountStatus } from "./useAccountStatus";

import { BookSupportCall } from "src/components/routes/Setup/common/components/BookSupportCall/bookSupportCall";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";

export const Statuses = {
  Successful: [RequiredActionsStatus.Successful],
  Unsuccessful: [
    RequiredActionsStatus.Unsuccessful,
    RequiredActionsStatus.CouldNotVerify,
  ],
  Pending: [
    RequiredActionsStatus.Pending,
    RequiredActionsStatus.PendingResponse,
    RequiredVerificationStatus.Pending,
  ],
  InReview: [
    RequiredActionsStatus.InReview,
    RequiredActionsStatus.Processing,
    RequiredActionsStatus.InAutomaticReview,
    RequiredVerificationStatus.InReview,
  ],
};

const AccountStatus: React.FC = () => {
  const { sendEvent } = useSegmentForSetup();
  const { status, partner, mutate: mutateAccountStatus } = useAccountStatus();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailRedirect = params.get("emailRedirect");
    if (emailRedirect === "true") {
      sendEvent(TrackingEvent.VERIFICATION_PENNY_TEST_REDIRECTED_FROM_EMAIL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RewardFeeDiscountDialogSetupVariant />
      <BookSupportCall />
      <OptimisedVerificationStatus
        status={status}
        partner={partner}
        mutateAccountStatus={mutateAccountStatus}
      />
    </>
  );
};

export default AccountStatus;
