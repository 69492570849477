import { FC, MouseEventHandler, useEffect, useState } from "react";
import {
  AlignItems,
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  Dialog,
  FontWeight,
  Glyph,
  Space,
  Text,
  TextAlign,
  VisuallyHidden,
} from "@gocardless/flux-react";
import { parseAsBoolean, useQueryState } from "nuqs";

import { OdometerAnimation } from "./OdometerAnimation";

import { ToTranslate } from "src/components/i18n";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { RewardsModal } from "src/components/rewards-modal/RewardsModal";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { TrackingEvent } from "src/common/trackingEvents";

export enum RewardFeeDiscountDestination {
  PACKAGE_SELECTION = "package selection",
  VERIFICATION_STATUS = "verification status",
}
interface RewardFeeDiscountDialogProps {
  destination: RewardFeeDiscountDestination;
  openModal?: boolean;
  onContinueClick?: () => void;
}

export const RewardFeeDiscountDialog: FC<RewardFeeDiscountDialogProps> = ({
  destination,
  openModal,
  onContinueClick,
}) => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });
  const { sendEvent } = useSegmentForSetup();
  const [open, setOpen] = useQueryState(
    "reward_fee_discount_dialog",
    parseAsBoolean
  );

  const [openRewardsModal, setOpenRewardsModal] = useState(false);

  const { activationDiscount, remainingDays } = useActivationDiscount();

  const handleContinueClick: MouseEventHandler<HTMLButtonElement> = () => {
    setOpen(null);
    onContinueClick?.();
    sendEvent(TrackingEvent.VERIFICATION_REWARD_DIALOG_CONTINUE_CLICKED);
  };

  const handleLearnMoreClick: MouseEventHandler<HTMLButtonElement> = () => {
    setOpenRewardsModal(true);
    sendEvent(TrackingEvent.VERIFICATION_REWARD_DIALOG_LEARN_MORE_CLICKED);
  };

  useEffect(() => {
    if (activationDiscount && !remainingDays) {
      setOpen(null);
    }
  }, [activationDiscount, remainingDays, setOpen]);

  useEffect(() => {
    if (!isVariationOn || !(open || openModal) || !remainingDays) return;

    sendEvent(TrackingEvent.VERIFICATION_REWARD_DIALOG_VIEWED, {
      amount_shown: remainingDays,
      destination,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationOn, remainingDays, open]);

  if (!isVariationOn) return null;

  const extraDays = 15;

  return (
    <>
      <Dialog open={(open || openModal) ?? false} css={{ minHeight: "430px" }}>
        <Box
          layout="flex"
          flexDirection="column"
          alignItems={AlignItems.Center}
          key="reward"
        >
          <OdometerAnimation startValue={1} endValue={remainingDays ?? 1} />

          <Space v={1} />
          <Text
            size={6}
            weight={FontWeight.SemiBold}
            textAlign={TextAlign.Center}
          >
            <ToTranslate>
              <VisuallyHidden>{remainingDays}</VisuallyHidden>
              Days left with no fees!
            </ToTranslate>
          </Text>

          <Space v={1} />

          <Text color={ColorPreset.TextOnLight_03} textAlign={TextAlign.Center}>
            {destination === RewardFeeDiscountDestination.PACKAGE_SELECTION ? (
              <ToTranslate>
                You&apos;ve unlocked {extraDays} extra days for your progress so
                far.
              </ToTranslate>
            ) : null}

            {destination ===
            RewardFeeDiscountDestination.VERIFICATION_STATUS ? (
              <ToTranslate>
                Great progress — you&apos;ve unlocked an additional {extraDays}{" "}
                days.
              </ToTranslate>
            ) : null}
          </Text>

          <Space v={2} />

          <Button
            variant={ButtonVariant.PrimaryOnLight}
            onClick={handleContinueClick}
          >
            <ToTranslate>Continue</ToTranslate>
          </Button>

          <Space v={1} />

          <Button
            variant={ButtonVariant.TextOnLight}
            leftIcon={Glyph.InfoCircle}
            size={ButtonSize.Sm}
            onClick={handleLearnMoreClick}
          >
            <ToTranslate>Learn more</ToTranslate>
          </Button>

          <Space v={[1, null, 0]} />
        </Box>
      </Dialog>
      <RewardsModal
        open={openRewardsModal}
        onClose={() => setOpenRewardsModal(false)}
      />
    </>
  );
};
