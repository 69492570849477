import {
  Box,
  Button,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  H6,
  JustifyContent,
  P,
  Space,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useState } from "react";
import { Trans } from "@lingui/macro";

import { useBookSupportCall } from "src/components/routes/Setup/common/components/BookSupportCall/useBookSupportCall";
import { BookMeetingModal } from "src/components/routes/Setup/common/components/BookSupportCall/bookMeetingModal";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

interface HelpGettingStartedProps {
  verificationStatus: CreditorsVerificationStatus;
}

export const HelpGettingStarted: React.FC<HelpGettingStartedProps> = ({
  verificationStatus,
}) => {
  const { sendEvent } = useSegment();
  const { bookSupportCall, showCard } = useBookSupportCall();
  const creditor = usePrimaryCreditor();
  const [bookMeetingOpen, setBookMeetingOpen] = useState<boolean>(false);

  if (!showCard) return <></>;
  const [textAlign, buttonAlign] =
    verificationStatus === CreditorsVerificationStatus.Successful
      ? [TextAlign.Center, JustifyContent.Center]
      : [TextAlign.Left, JustifyContent.FlexStart];
  return (
    <Box
      bg={ColorPreset.BackgroundLight_01}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={1}
      gutterV={[2, 4]}
      gutterH={[2, 4]}
      spaceAbove={[1.5, 3]}
    >
      <H6 preset={TypePreset.Heading_06} textAlign={textAlign}>
        <Trans>Want some help getting started?</Trans>
      </H6>
      <Space v={2} />
      <P preset={TypePreset.Body_01} textAlign={textAlign}>
        <Trans>
          Book a free session with one of our onboarding specialists to learn
          how to use GoCardless, get answers to your questions, and ensure
          you’re set up for success.
        </Trans>
      </P>
      <Space v={2} />
      <Box layout="flex" justifyContent={buttonAlign}>
        <Button
          layout={[ButtonLayout.Full, ButtonLayout.Inline]}
          variant={ButtonVariant.SecondaryOnLight}
          size={ButtonSize.Md}
          onClick={() => {
            bookSupportCall();
            sendEvent(TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_CTA_CLICKED, {
              geo: creditor?.geo,
            });
            setBookMeetingOpen(true);
          }}
        >
          <Trans>Book a call</Trans>
        </Button>
      </Box>
      <BookMeetingModal
        open={bookMeetingOpen}
        closeDialog={() => setBookMeetingOpen(false)}
      />
    </Box>
  );
};
