import {
  CreditorType,
  RequiredActionsName,
} from "@gocardless/api/dashboard/types";
import { Box } from "@gocardless/flux-react";

import { getUploadConfig } from "../config";
import { RequiredDocument } from "../required-documents/useRequiredDocuments";
import { getInReview, getPending } from "../helpers/requiredDocumentsFilter";

import { RequiredActionCard } from "./RequiredActionCard";

import { CountryCodes } from "src/common/country";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { VERIFICATION_IN_REVIEW_STATES } from "src/components/routes/Setup/verification-status/constants/VerificationStates";

const shouldFilterInReviewDocuments = (
  requiredDocumentsInReview: RequiredDocument[],
  isPennyTestImprovementsEnabled: boolean
) =>
  // we only want to remove this tile if bank statement is the only check in review
  isPennyTestImprovementsEnabled &&
  requiredDocumentsInReview.length === 1 &&
  requiredDocumentsInReview[0]?.name ===
    RequiredActionsName.ProvideBankStatement;
export const DocumentsToUpload: React.FC<{
  creditorId: string;
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  requiredDocuments: RequiredDocument[];
  updateRequiredDocuments: () => Promise<void>;
  notificationEmail: string;
  setMutationFetchingPaused?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  creditorId,
  creditorGeo,
  creditorType,
  requiredDocuments,
  updateRequiredDocuments,
  notificationEmail,
  setMutationFetchingPaused,
}) => {
  const { isVariationOn: isPennyTestImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PENNY_TEST_IMPROVEMENTS,
    });
  const { isVariationOn: isKbisUploadsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.VERIFICATIONS_IMPROVE_KBIS_UPLOADS,
  });
  const pending = getPending(requiredDocuments);
  const allInReview = getInReview(requiredDocuments);
  const filteredInReview = allInReview.filter(
    ({ name }) =>
      isPennyTestImprovementsEnabled &&
      name !== RequiredActionsName.ProvideBankStatement
  );
  const inReview = shouldFilterInReviewDocuments(
    allInReview,
    isPennyTestImprovementsEnabled
  )
    ? filteredInReview
    : allInReview;

  const items = [...pending, ...inReview].map((document) => {
    const {
      status: actionStatus,
      entity,
      name,
      verificationId,
      bankAccountNumberEnding,
    } = document;
    const { actionComponent, acceptedDocuments, documentRequirements } =
      getUploadConfig(name, creditorGeo, isKbisUploadsEnabled);

    // No longer want to render the card if there is no pending action from the merchant:
    if (
      !actionComponent ||
      VERIFICATION_IN_REVIEW_STATES.includes(actionStatus)
    )
      return null;

    return (
      <Box key={verificationId} spaceAbove={[1.5, 3]}>
        <RequiredActionCard
          creditorId={creditorId}
          creditorGeo={creditorGeo}
          creditorType={creditorType}
          verificationId={verificationId}
          bankAccountNumberEnding={bankAccountNumberEnding}
          entity={entity}
          actionComponent={actionComponent}
          actionStatus={actionStatus}
          acceptedDocuments={acceptedDocuments}
          documentRequirements={documentRequirements}
          updateRequiredDocuments={updateRequiredDocuments}
          notificationEmail={notificationEmail}
          setMutationFetchingPaused={setMutationFetchingPaused}
        />
      </Box>
    );
  });

  return <Box>{items}</Box>;
};
