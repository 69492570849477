import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useLingui } from "@lingui/react";
import { useCallback } from "react";

import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/technical-integrations/optimizely/constants";
import {
  useVerificationStatus,
  usePrimaryCreditor,
} from "src/libraries/creditor/hooks";
import { useLocalStorage } from "src/hooks/useLocalStorage/useLocalStorage";
import { useContacts } from "src/components/routes/Setup/contact-details/useContacts";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

interface UseBookSupportCall {
  bookSupportCall: () => void;
  bookingUrl: string;
  showDialog: boolean;
  showCard: boolean;
  onDismissDialog: () => void;
  sendBookCallEvent: (eventName: TrackingEvent) => void;
}

export const DASHBOARD_BOOK_SUPPORT_CALL_DISMISSED =
  "gc.dashboard.book.support.call.dismissed";
export const CHILLI_PIPER_DOMAIN = "https://gocardless.chilipiper.com";
export const CHILLI_PIPER_MEETING_QUEUE_MAPPING: {
  [key: string]: string;
} = {
  "en-GB": "standard-success",
  "es-ES": "esp-self-serve-scs-a-a",
  "fr-FR": "fr-self-serve-scs-a-a",
  "de-DE": "dach-self-serve-scs-a-a",
};
export const DEFAULT_MEETING_QUEUE = "standard-success";

export function useBookSupportCall(): UseBookSupportCall {
  const { i18n } = useLingui();
  const { sendEvent } = useSegment();
  const { isVariationOn: bookSupportCallEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_BOOK_OEX_CALL,
    variationKey: OptimizelyVariant.FLAG_ON,
  });
  const { status: verificationStatus } = useVerificationStatus();
  const { billingContact } = useContacts();
  const creditor = usePrimaryCreditor();
  const [isDismissed, setIsDismissed] = useLocalStorage(
    DASHBOARD_BOOK_SUPPORT_CALL_DISMISSED,
    String(false)
  );

  const meetingQueue =
    CHILLI_PIPER_MEETING_QUEUE_MAPPING[i18n.locale] || DEFAULT_MEETING_QUEUE;
  const bookingUrl = new URL(`/book/${meetingQueue}`, CHILLI_PIPER_DOMAIN);
  bookingUrl.searchParams.append("firstname", billingContact.givenName);
  bookingUrl.searchParams.append("lastname", billingContact.familyName);
  bookingUrl.searchParams.append("email", billingContact.email);
  bookingUrl.searchParams.append(
    "phone",
    billingContact.phoneNumber.replace(/\s/g, "")
  );

  const sendBookCallEvent = useCallback(
    (eventName: TrackingEvent) => {
      sendEvent(eventName, {
        booking_queue: meetingQueue,
        geo: creditor?.geo,
      });
    },
    [sendEvent, meetingQueue, creditor?.geo]
  );

  const showCard =
    bookSupportCallEnabled &&
    !creditor?.activated &&
    (verificationStatus === CreditorsVerificationStatus.InReview ||
      verificationStatus === CreditorsVerificationStatus.Successful);
  const showDialog = showCard && isDismissed === String(false);

  return {
    bookSupportCall: () => {
      sendBookCallEvent(
        TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_CALENDAR_VIEWED
      );
      setIsDismissed(String(true));
    },
    bookingUrl: bookingUrl.toString(),
    showDialog,
    showCard,
    onDismissDialog: () => setIsDismissed(String(true)),
    sendBookCallEvent,
  };
}
