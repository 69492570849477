import {
  Box,
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  Dialog,
  JustifyContent,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { useEffect } from "react";

import referralJewel from "src/assets/svg/referral-jewel.svg";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { TrackingEvent } from "src/common/trackingEvents";

interface BookSupportCallModalProps {
  open: boolean;
  closeDialog: () => void;
  bookSupportCall: () => void;
}
export const BookSupportCallModal: React.FC<BookSupportCallModalProps> = ({
  open,
  closeDialog,
  bookSupportCall,
}) => {
  const { sendEvent } = useSegment();
  const creditor = usePrimaryCreditor();
  const geo = creditor?.geo;

  useEffect(() => {
    if (open) {
      sendEvent(TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_DIALOG_VIEWED, {
        geo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Dialog
      open={open}
      closeAction={{ onClose: closeDialog, label: "Close" }}
      header={{ content: <></>, separator: false }}
      footer={{
        content: (
          <ButtonGroup arrangement={["column-center", "row-end-reverse"]}>
            <Button
              layout={[ButtonLayout.Full, ButtonLayout.Inline]}
              variant={ButtonVariant.PrimaryOnLight}
              size={[ButtonSize.Lg, ButtonSize.Sm]}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_DIALOG_CLICKED,
                  { geo, target: "Yes book a call" }
                );
                bookSupportCall();
              }}
            >
              <Trans>Yes, book a call</Trans>
            </Button>
            <Button
              layout={[ButtonLayout.Full, ButtonLayout.Inline]}
              variant={ButtonVariant.TextOnLight}
              size={[ButtonSize.Lg, ButtonSize.Sm]}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_ONBOARDING_BOOK_CALL_DIALOG_CLICKED,
                  { geo, target: "No maybe later" }
                );
                closeDialog();
              }}
            >
              <Trans>No, maybe later</Trans>
            </Button>
          </ButtonGroup>
        ),
        separator: true,
      }}
      bodyGutterV={0}
    >
      <Box
        layout="flex"
        borderRadius={1}
        gutterV={0.5}
        bg={Color.Ultraviolet_100}
        justifyContent={JustifyContent.Center}
      >
        <Image src={referralJewel} alt="" width={192} height={192} />
      </Box>
      <Box gutterV={2}>
        <Text preset={TypePreset.Heading_03}>
          <Trans>Would you like to book a call to help you get started?</Trans>
        </Text>
        <Space v={1} />
        <Text preset={TypePreset.Body_01}>
          <Trans>
            Book a free session with one of our onboarding specialists to learn
            how to use GoCardless, answer any questions you may have, and ensure
            you’re set up for success.
          </Trans>
        </Text>
      </Box>
    </Dialog>
  );
};
