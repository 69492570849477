import { useState } from "react";
import {
  AlignItems,
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Color,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  IconButton,
  JustifyContent,
  PlainButton,
  PlainLink,
  Space,
  Visibility,
} from "@gocardless/flux-react";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

import { useSegmentForSetup } from "../../hooks/useSegmentForSetup";

import GoCardlessLogo from "src/components/GoCardlessLogo";
import ProgressBar from "src/components/ui/ProgressBar/ProgressBar.new";
import { Route } from "src/common/routing";
import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { HelpModal } from "src/components/routes/Setup/common/components/HelpModal";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

export const SIDEBAR_STEPS_BUTTON_ID = "sidebar-steps-button";

export interface HeaderProps {
  setupPage: SetupPages;
  setupSteps: {
    page: SetupPages;
    route: Route;
    completed: boolean;
  }[];
  openSideBar: () => void;
}

const getProgress = (
  setupPage: SetupPages,
  setupSteps: { page: SetupPages }[]
): number => {
  const stepNumber = setupSteps.findIndex((step) => step.page === setupPage);
  if (stepNumber === 0 || setupSteps.length < 1) {
    return 0;
  }
  const progress = (stepNumber + 1) / (setupSteps.length - 1);
  return progress > 1 ? 0 : progress;
};

const Header: React.FC<HeaderProps> = ({
  setupPage,
  setupSteps,
  openSideBar,
}) => {
  const { sendEvent } = useSegmentForSetup();
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const creditor = usePrimaryCreditor();
  const toggleHelpModalOpen = () => setHelpModalOpen((previous) => !previous);

  return (
    <Box spaceBelow={[2, 3]}>
      <Box gutterV={[1, 1.5]} spaceBefore={[1, 3]} spaceAfter={[2, 3]}>
        <Box
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.Center}
        >
          <Box layout="inline-block">
            <Visibility visible={["inline-block", "none"]}>
              <Box
                layout="flex"
                flexDirection="row"
                alignItems={AlignItems.Center}
              >
                <IconButton
                  variant={ButtonVariant.TextOnLight}
                  css={{ marginTop: "3px" }}
                  label="Menu"
                  icon={Glyph.Menu}
                  onClick={() => {
                    sendEvent(MerchantOnboardingSetupEvents.StepsSidebarOpened);
                    openSideBar();
                  }}
                />
                <Space h={0.5} />
                <PlainLink href="/" css={{ paddingTop: "8px" }}>
                  <GoCardlessLogo variant="dark" size={["sm", "md"]} />
                </PlainLink>
              </Box>
            </Visibility>
            <Visibility visible={["none", "inline-block"]}>
              <PlainLink href="/" css={{ paddingTop: "8px" }}>
                <GoCardlessLogo variant="dark" size={["sm", "md"]} />
              </PlainLink>
            </Visibility>
          </Box>
          <Box layout="inline-block">
            <Visibility visible={["none", "inline-block"]}>
              <PlainButton
                effect={HoverEffect.TextDecoration}
                weight={FontWeight.Bold}
                onClick={() => {
                  sendEvent(MerchantOnboardingSetupEvents.StepsSidebarOpened);
                  openSideBar();
                }}
                id={SIDEBAR_STEPS_BUTTON_ID}
              >
                <Icon name={Glyph.Menu} size="12px" />
                <Space layout="inline" h={0.5} />
                {creditor?.verification_status ===
                CreditorsVerificationStatus.Successful
                  ? i18n._(
                      t({
                        id: "setup.header.account-details",
                        message: "Account details",
                      })
                    )
                  : i18n._(t({ id: "setup.header.steps", message: "Steps" }))}
              </PlainButton>
              <Space layout="inline" h={1.5} />
            </Visibility>
            <Button
              variant={ButtonVariant.SecondaryOnLight}
              size={ButtonSize.Sm}
              onClick={() => toggleHelpModalOpen()}
            >
              <Trans id="Help">Help</Trans>
            </Button>
            <HelpModal {...{ helpModalOpen, toggleHelpModalOpen }} />
          </Box>
        </Box>
      </Box>
      <ProgressBar
        primaryColor={Color.Info_300}
        secondaryColor={Color.Greystone_1400_A16}
        progressPercentage={getProgress(setupPage, setupSteps)}
        width="100%"
        height="4px"
        gapSize="0"
      />
    </Box>
  );
};

export default Header;
