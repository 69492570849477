import { CreditorType } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";

import { RequiredDocument } from "../required-documents/useRequiredDocuments";

import { StatusCard } from "./StatusCard";
import { StatusList } from "./StatusList";

import { CountryCodes } from "src/common/country";

export const ActionRequired: React.FC<{
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  requiredDocuments: RequiredDocument[];
}> = ({ creditorGeo, creditorType, requiredDocuments }) => (
  <StatusCard
    icon="AccountVerifyingTick"
    title={<Trans>We require further information</Trans>}
    description={
      <Trans>
        We need some more details to complete your account verification.
      </Trans>
    }
    body={
      <StatusList
        creditorGeo={creditorGeo}
        creditorType={creditorType}
        requiredDocuments={requiredDocuments}
      />
    }
  />
);
